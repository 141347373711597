import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Api from '@launchpad/logic/api/Api';
import AccountBasicInfo from './tabs/AccountBasicInfo';
import AccountUserDetails from './tabs/AccountUserDetails';
import AccountTransactions from './tabs/AccountTransactions';
import BreadcrumbHelper from '../../../../../@launchpad/util/BreadcrumbHelper';
import AppTabs from '../../../../../@launchpad/components/crud/tabs/AppTabs';
import Logger from '../../../../../@launchpad/util/Logger';
import FeeCollection from '../../../../../@launchpad/modules/fee/FeeCollection';

export default class AccountDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: {},
      activeTab: 'basic'
    };
  }

  componentDidMount() {
    this._getAccountDetails();
  }

  getTabs(account) {
    const basicInfo = (
      <AccountBasicInfo
        account={account}
        onRefresh={() => this._getAccountDetails()}
      />
    );
    const allTabs = [
      {
        id: 'basic',
        icon: 'info',
        title: 'Basic Info',
        component: basicInfo
      },
      {
        id: 'user',
        icon: 'user',
        title: 'User details',
        component: <AccountUserDetails userId={account.userUuid} />
      },
      {
        id: 'fee-collection',
        icon: 'money',
        title: 'Fee Collection',
        component: <FeeCollection account={account} {...this.props} />
      },
      {
        id: 'transactions',
        icon: 'exchange',
        title: 'Account Transactions',
        component: <AccountTransactions account={account} {...this.props} />
      }
    ];
    return allTabs;
  }

  async _getAccountDetails() {
    const { id } = this.props.match.params;
    const getUrl = `admin/accounts/${id}`;

    try {
      const result = await Api.call(getUrl, 'GET');
      this.setState(
        {
          account: result.data
        }
        // () => {
        //   if (this.state.account?.name) {
        //     BreadcrumbHelper.addCrumb(this.state.account.name);
        //   }
        // }
      );
    } catch (e) {
      Logger.log('Loading error', e);
    }
  }

  /**
   * Render
   *
   * @returns {XML}
   */
  _renderContent(accountDetails) {
    return (
      <div>
        <h1>
          <i className="fa fa-info" aria-hidden="true" /> Account Info:{' '}
          {accountDetails.name}
        </h1>
        <AppTabs
          onTabChange={activeTab => this.setState({ activeTab })}
          activeTab={this.state.activeTab}
          tabs={this.getTabs(accountDetails)}
        />
      </div>
    );
  }

  render() {
    BreadcrumbHelper.setCrumbs([
      {
        label: 'Accounts',
        link: '/accounts'
      },
      {
        label: this.state.account.name
      }
    ]);
    return (
      <div className="dashboard-management">
        {this._renderContent(this.state.account)}
      </div>
    );
  }
}

// Inject router
AccountDetails.contextTypes = {
  router: PropTypes.object
};
