import { AppConfig } from '@launchpad/types';

const config: Partial<AppConfig> = {
  env: process.env.REACT_APP_ENVIRONMENT || 'dev',
  url: process.env.REACT_APP_URL || 'http://localhost',
  authStrategy: 'refresh-token',
  ignorePermissions: true,
  policiesEnabled: false,
  pluginName: 'velapay',
  allowUserDetailsEdit: true
};

export default config;
